/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.texto-pequeno {
    font-size: xx-small;
}
.tableHeader{
  font-size: large;
  font-weight: bold;
}
.ion-select {
  width: 100% !important;
  font-size: xx-small;
}
.texto-mediano {
  font-size:medium;
}

.texto-blanco{
  color: white;
}

.tab-button {
  min-width: 20px;
}
/* Set the flex in order to size the text width to its content 
.ion-select::part(placeholder),
.ion-select::part(text) {
  flex: 0 0 auto;
}
*/

@media screen and (max-width: 899px) {
  .tamanoPantalla{
    display: none !important;
  }
}  

@media screen and (min-width: 900px) {
  .tamanoPantalla{
    visibility: visible !important;
  }
}